.not-found {
    .section {
        margin-top: 10vh;
        margin-left: 7.85rem;
        color: white;
    }

    h1 {

        font-size: 15.7rem;
        text-stroke-width: 0.35rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    strong {
        color: inherit;
    }
}
